<template>
    <div class="content-main">
        <router-link to="/service-case" class="toHome"></router-link>
        <img class="img" src="../assets/anli/case5.jpg" />
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.content-main {
    position: relative;
}
.toHome {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 60px;
    right: 80px;
    cursor: pointer;
}

.content-main img {
    width: 100%;
    height: 633px;
}
</style>